import React, { useCallback, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks';
import { FullLoading } from 'components';

import logo from '../../assets/general/f50bc46c45a94eb0be58d2e71941949a.png';
import message from '../../assets/general/c657ffa07926445490142ff4e763863b.png';

import { FaFacebook, FaInstagram, FaDiscord, FaYoutube } from 'react-icons/fa6';
// import defaultLogo from '../../assets/general/default.png';
import './styles.css';

const links = {
  facebook: 'https://facebook.com/profile.php?id=61559872930427',
  instagram: 'https://instagram.com/brasileiros.na.europa.oficial',
  discord: process.env.REACT_APP_DISCORD_INVITE_URL,
  youtube: 'https://youtube.com/@BrasileirosNaEuropaCS',
};

function Landing() {
  const { getCredentials, user } = useAuth();
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (search.includes('code=')) {
      getCredentials({ code: search.replace('?code=', '') }).then((response) => {
        try {
          if(!response){
            return;
          }
          localStorage.setItem(`${process.env.REACT_APP_TOKEN}`, response.token)
          localStorage.setItem(`${process.env.REACT_APP_USER}`, JSON.stringify(response.enrichedUser))
          navigate(`/home`, {});

        } catch (e) {
          console.log(e);
        }

      })
      // getCredentials({ code: search.replace('?code=', '') }).then(() => {
      //   navigate(`/home`, {});
      // });
    }
  }, []);

  const handleNavigation = useCallback(() => {
    if (user) {
      navigate(`/home`, {});
      return;
    }

    const anchor = document.querySelector("a[id='auth']");

    if (anchor) {
      anchor.click();
    }
  }, [user, navigate]);

  return (
    <div className="flex flex-col relative overflow-hidden w-screen h-screen select-none">
      <FullLoading />
      <div id="header" className="text-white text-end px-4 py-2 flex flex-col-reverse md:flex-row justify-between">
        <div className="text-white flex flex-row items-center justify-between  py-2 w-48 gap-x-4 mx-auto md:mx-0 px-0 md:px-4">
          <a href={links.facebook} target="_blank" rel="noreferrer" className="base-class facebook">
            <FaFacebook className="h-6 w-auto" />
          </a>
          <a href={links.instagram} target="_blank" rel="noreferrer" className="base-class instagram">
            <FaInstagram className="h-6 w-auto " />
          </a>
          <a href={links.discord} target="_blank" rel="noreferrer" className="base-class discord">
            <FaDiscord className="h-6 w-auto" />
          </a>
          <a href={links.youtube} target="_blank" rel="noreferrer" className="base-class youtube">
            <FaYoutube className="h-6 w-auto" />
          </a>
        </div>
        <button onClick={handleNavigation} className="duration-300 opacity-80 hover:opacity-100 drop-shadow-xl flex flex-row items-center justify-center gap-x-3 text-2xl px-4 py-2 border-indigo-600 bg-indigo-600 rounded-md">
        <p className="font-semibold">Acesse com</p> <FaDiscord className="h-10 w-auto" />
        </button>
      </div>

      {/* <div className="text-white mx-auto my-auto w-screen flex flex-col justify-center items-center">
        <div className="flex flex-row items-center justify-between py-10 px-5 relative w-full h-full">
          <img src={message} className="min-h-96 w-auto" style={{ height: '520px' }}></img>
          <img src={logo} className="min-h-96 w-auto mx-auto" style={{ height: '420px' }}></img>
        </div>
      </div> */}

      <div className="flex flex-wrap">
        {/* <!-- IMAGE_A --> */}
        <div className="w-full xl:w-3/4 p-2">
          <img src={message} alt="Wide Image" className="w-full h-auto object-cover" />
        </div>

        {/* <!-- IMAGE_B --> */}
        <div className="w-1/2 xl:w-1/4 xl:my-auto mx-auto xl:mx-0 p-2">
          <img src={logo} alt="Square Image" className="w-full h-auto object-cover" />
        </div>
      </div>
      <a id="auth" href={process.env.REACT_APP_DISCORD_OAUTH} style={{ display: 'none' }} />
    </div>
  );
}

export default Landing;
