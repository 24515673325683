import React, { createContext, useCallback, useContext } from 'react';
import { useAuth } from './auth'

const HTTPErrorContext = createContext({});

function HTTPErrorProvider({ children }) {
  const { signOut } = useAuth();
  const errorHandler = useCallback((e) => {
    if(!!e.status && e.status !== 500){
      signOut();
    }
  }, [signOut]);

  return (
    <HTTPErrorContext.Provider value={{ errorHandler }}>
      {children}
    </HTTPErrorContext.Provider>
  );
}



function useHttpError() {
  const context = useContext(HTTPErrorContext);

  if (!context) {
    throw new Error('useEncoder must be used within an EncoderProvider');
  }

  return context;
}

export { useHttpError, HTTPErrorProvider };
