import React, { useEffect, useCallback, useState } from 'react';

import { useAuth, useLoading, useMonth, useHttpError } from 'hooks';

import { useNavigate } from 'react-router-dom';

import { Chart as ChartJS } from 'chart.js/auto';

ChartJS.defaults.color = '#a6a6a6';
ChartJS.defaults.borderColor = 'rgba(255,255,255,0.25)';

ChartJS.defaults.elements.line.borderCapStyle = 'round';
ChartJS.defaults.elements.line.tension = '0.25';

import { Line } from 'react-chartjs-2';

import Pagination from './pagination';

import { FaAngleDoubleDown, FaAngleDoubleUp, FaEquals, FaExternalLinkAlt } from 'react-icons/fa';
import { TfiTarget } from 'react-icons/tfi';
import { FaSkull } from 'react-icons/fa6';

import { format } from 'date-fns';
import {rankMaps} from './rank_images_helper';
import endpoint from 'service/endpoint';

const { REACT_APP_USER_MATCHES, REACT_APP_USER_SEASON_STATS, REACT_APP_CURRENT_RANKING_SNAPSHOT } = process.env;

export default function Home() {
  const { errorHandler } = useHttpError();
  const { signOut } = useAuth();
  const { toggleLoading } = useLoading();
  const { season } = useMonth();
  const navigate = useNavigate();

  const [seasonStats, setSeasonStats] = useState(() => {
    const localStats = localStorage.getItem(REACT_APP_USER_SEASON_STATS);
    if (localStats && !!JSON.parse(localStats)) {
      return JSON.parse(localStats);
    }
    return [];
  });

  const [matchesData, setMatchesData] = useState(() => {
    const localStats = localStorage.getItem(REACT_APP_USER_MATCHES);
    if (localStats) {
      return JSON.parse(localStats).slice(0, 10);
    }
    return [];
  });

  const [matches, setMatches] = useState(() => {
    const localStats = localStorage.getItem(REACT_APP_USER_MATCHES);
    if (localStats) {
      return JSON.parse(localStats);
    }
    return [];
  });
  const [rankingSnapshots, setRankingSnapshots] = useState(() => {
    const localStats = localStorage.getItem(REACT_APP_CURRENT_RANKING_SNAPSHOT);
    if (localStats) {
      return JSON.parse(localStats);
    }
    return [];
  });
  //

  const fetchStats = useCallback(async () => {
    toggleLoading();
    try {
      const storedSeasonStats = localStorage.getItem(REACT_APP_USER_SEASON_STATS);
      const storedUserMatches = localStorage.getItem(REACT_APP_USER_MATCHES);
      const storedRankingSnapshots = localStorage.getItem(REACT_APP_CURRENT_RANKING_SNAPSHOT);

      // const response = await api.get('/system/stats.php');
      const response = await endpoint.get(`/user/stats`);

      const { season, matches, snapshots } = response;

      if (!season | !matches) {
        return;
      }

      let cumulative = 0;
      const mappedMatches = matches.map((item, index) => {
        cumulative = (index === 0 ? season.totalpoints : (cumulative - parseInt(matches[index-1].totalpoints, 10)))
        return {...item, evolution: cumulative};
      });

      const mappedSnapshots = snapshots.map((item, index) => {
        let variation = 0;

        if(index < snapshots.length - 1){
          variation = snapshots[index+1].position - item.position;
        } else {
          //
        }

        return {...item, variation};
      }).slice(0,5)

      if (!storedSeasonStats || (!!storedSeasonStats && JSON.stringify(season) !== storedSeasonStats)) {
        localStorage.setItem(REACT_APP_USER_SEASON_STATS, JSON.stringify(season));
        setSeasonStats(season);
      }
      if (!storedUserMatches || (!!storedUserMatches && JSON.stringify(mappedMatches) !== storedUserMatches)) {
        localStorage.setItem(REACT_APP_USER_MATCHES, JSON.stringify(mappedMatches));
        setMatches(mappedMatches);
        setMatchesData(mappedMatches.slice(0,10))
      }
      if (!storedRankingSnapshots || (!!storedRankingSnapshots && JSON.stringify(mappedSnapshots) !== storedRankingSnapshots)) {
        localStorage.setItem(REACT_APP_CURRENT_RANKING_SNAPSHOT, JSON.stringify(mappedSnapshots));
        setRankingSnapshots(mappedSnapshots);
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      toggleLoading();
    }
  }, [signOut]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const handleRankNavigation = useCallback(() => {
    navigate(`/ranking`, {});
  }, []);

  return (
    // <Container>
    <div className="container">
      <div className="flex flex-col px-10 select-none ">
        <div className="mt-10 mb-5 text-lg md:text-2xl text-white w-full md:w-4/6 mx-auto">BNE Premier | Season {season} - {new Date().getFullYear()}</div>
        <div className="grid grid-cols-2 lg:grid-cols-4 mx-auto mt-5 gap-3 md:gap-4 lg:gap-5">
          <div
            onClick={handleRankNavigation}
            className="cursor-pointer relative h-20 sm:h-28 md:h-40 w-36 sm:w-44 md:w-56 border border-stone-500/60 rounded-md flex flex-col justify-center px-5 bg-zinc-800"
          >
            <div className="flex flex-row items-center text-xs sm:text-sm md:text-base text-stone-400">Ranking Atual</div>
            <div className="text-white text-xl sm:text-2xl md:text-4xl lg:text-7xl text-center">{seasonStats.position}°</div>
            <div className="flex flex-row items-center justify-center text-xs sm:text-sm md:text-base text-stone-400 mt-2 gap-0 md:gap-2">
              <span>{seasonStats.totalpoints} points </span>
              <span className="hidden md:inline-block">({seasonStats.won}V | {seasonStats.lost}D)</span>
              {/* NOTE: review on xs */}

            </div>
            <div className="absolute top-1 right-1 md:top-3 md:right-3 text-white">
              <FaExternalLinkAlt />
            </div>
          </div>

          <div className="h-20 sm:h-28 md:h-40 w-36 sm:w-44 md:w-56 border border-stone-500/60 rounded-md flex flex-col justify-center px-5 bg-zinc-800">
            <div className="flex flex-row items-center text-xs sm:text-sm md:text-base text-stone-400 gap-0 md:gap-2">
              <span>KDR</span>
              <span className="hidden md:inline-block">(Kill Death Ratio)</span>
            </div>
            <div className="text-white text-xl sm:text-2xl md:text-4xl lg:text-7xl text-center">{seasonStats.kdr}</div>
            <div className="flex flex-row justify-center items-center gap-2 text-stone-400 mt-2">
              <span className="text-xs sm:text-sm md:text-base flex flex-row justify-center items-center gap-1">
                <TfiTarget /> {seasonStats.kills}
              </span>
              <span>|</span>
              <span className="text-xs sm:text-sm md:text-base flex flex-row justify-center items-center gap-1">
                <FaSkull /> {seasonStats.deaths}
              </span>
            </div>
          </div>

          <div className="h-20 sm:h-28 md:h-40 w-36 sm:w-44 md:w-56 border border-stone-500/60 rounded-md flex flex-col justify-center px-5 bg-zinc-800">
            <div className="flex flex-row items-center text-xs sm:text-sm md:text-base text-stone-400">Headshots (%)</div>
            <div className="text-white text-xl sm:text-2xl md:text-4xl lg:text-7xl text-center">{seasonStats.hsr ? parseFloat(seasonStats.hsr.toFixed(1)) : '-'}%</div>
            <div className="text-stone-400 mt-2">&nbsp;</div>
          </div>

          <div className="h-20 sm:h-28 md:h-40 w-36 sm:w-44 md:w-56 border border-stone-500/60 rounded-md flex flex-col justify-center px-5 bg-zinc-800">
            <div className="text-xs sm:text-sm md:text-base text-stone-400">MVPs</div>
            <div className="text-white text-xl sm:text-2xl md:text-4xl lg:text-7xl text-center">{seasonStats.mvp}</div>
            <div className="text-stone-400 mt-2">&nbsp;</div>
          </div>
        </div>
        <div className="mt-10 text-2xl text-white w-full md:w-4/6 mx-auto">
          <div className="gap-x-8 mx-auto grid grid-cols-1 md:grid-cols-2 gap-y-3 md:gap-y-0">
            <div className="bg-zinc-800 rounded-md px-6">
            <div className='text-xs text-center'>Evolução Pontos</div>
              <Line
                options={{
                  title: {
                    display: false,
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: 'right',
                  },
                }}
                data={{
                  labels: matchesData.map((data) => data.map.map_label).reverse(),
                  datasets: [
                    {
                      label: 'Pontos',
                      data: matchesData.map((item) => item.evolution).reverse(),
                      backgroundColor: '#d6d6d6',
                      borderColor: '#039BE5',
                      borderWidth: 2,
                    },
                  ],
                }}
              />
            </div>
            <div className="bg-zinc-800 rounded-md px-6">
              <div className='text-xs text-center mb-2'>Histórico Ranking</div>
              {rankingSnapshots.map((item) => (
                <div key={item.snapshotdate} className='border-b-slate-500 border-b last:border-b-0 text-xs md:text-sm flex flex-row justify-center gap-x-8'>
                  <div className={`flex flex-row items-center justify-center` }>
                    {format(item.snapshotdate, 'dd/MM/yyyy')}
                  </div>
                  <div className={`flex flex-row items-center justify-center gap-x-2 ${item.variation < 0 ? 'text-red-500': item.variation > 0 ? 'text-emerald-500': 'text-slate-300'} ` }>
                    {item.variation < 0 ? (<FaAngleDoubleDown />) : item.variation > 0 ? (<FaAngleDoubleUp />) : (<FaEquals />)}
                    {item.position}
                  </div>
                  <div className='flex flex-row items-center gap-x-1'>
                    <img src={rankMaps.get(item.rank)} className='h-8 w-auto'></img>
                    <span>
                      {item.totalpoints}
                    </span>
                  </div>
                </div>
              ))}
              {/* <Line
                options={{
                  title: {
                    display: false,
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: 'right',
                  },
                }}
                data={{
                  labels: rankingSnapshots.map((data) => format(data.snapshotdate, 'dd/MM/yyyy')).reverse(),
                  datasets: [
                    {
                      label: 'Histórico Ranking',
                      data: rankingSnapshots.map((item) => item.rank).reverse(),
                      backgroundColor: '#7159c1',
                      borderColor: '#fff',
                    }
                    // ,
                    // {
                    //   label: 'Deaths',
                    //   data: matchesData.map((item) => item.deaths),
                    //   backgroundColor: '#c53030',
                    //   borderColor: '#c53030',
                    // },
                  ],
                }}
              /> */}
            </div>
          </div>
        </div>
        <div className="mt-10 text-2xl text-white">
          <Pagination data={matches} maximumItems={10}></Pagination>
        </div>
      </div>
    </div>
    // <Dialog open={open} onClose={setOpen} className="relative z-50">
    //   <DialogBackdrop transition className="fixed inset-0 bg-zinc-900 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0" />

    //   <div className="fixed inset-0 overflow-hidden">
    //     <div className="absolute inset-0 overflow-hidden">
    //       <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
    //         <DialogPanel transition className="pointer-events-auto relative w-screen max-w-xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
    //           <div className="flex h-full flex-col overflow-y-scroll bg-zinc-800 py-6 shadow-xl text-white">
    //             <div className="px-4 sm:px-6">
    //               <DialogTitle className="text-xl text-sky-300 underline font-semibold leading-6">BNE Premier - Season 1 | Top 10</DialogTitle>
    //             </div>
    //             <div className="relative mt-6 flex-1 px-4 sm:px-6">
    //               <div className="text-white  h-full flex flex-col justify-between">
    //                 {ranking.slice(0, 10).map((item) => (
    //                   <div key={item.steamid64} className="border rounded-md  border-zinc-200/40 ">
    //                     <div className="relative bg-zinc-200/15 py-2 px-4">
    //                       <div className="flex items-center">
    //                         <img src={item.rank} className="h-6 w-auto mr-2" />
    //                         <div className="text-xl">{item.name}</div>
    //                       </div>
    //                       {item.win_streak >= 3 && (
    //                         <div className="absolute top-1/2 -translate-y-1/2 right-4 flex items-center text-sm">
    //                           <span className="text-amber-600">
    //                             <FaFire />
    //                           </span>
    //                           <span className="ml-2 text-amber-200">{item.win_streak} win streak!!</span>
    //                         </div>
    //                       )}
    //                     </div>
    //                     <div className="mt-2">HERE COMES MORE INFO CONTENT</div>
    //                   </div>
    //                 ))}
    //               </div>
    //             </div>
    //           </div>
    //         </DialogPanel>
    //       </div>
    //     </div>
    //   </div>
    // </Dialog>
    // </Container>
  );
}
