import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from "react-hook-form"

import { useSkins, Squad, Mode,Tier, mappedMode, useAuth, mappedSquad, useHttpError } from 'hooks'
import { Dialog, DialogPanel } from '@headlessui/react'
import { FaCirclePlus, FaCircleMinus } from 'react-icons/fa6';
import './styles.css';

import endpoint from 'service/endpoint';
import { FaArrowCircleLeft, FaTimes, FaCheckCircle } from 'react-icons/fa';

function PremierResource({children}){
  const { user } = useAuth();
  return  !user.isAdmin && !user.isMod && user.tier !== Tier.T3 && (
    <div className="text-xs font-normal mb-5">
      {children}
    </div>
  )
}

// function HeaderButton({team, type, label, classes = [], helperClasses = [], content, contentKey, handleClick, disable}){
//   const { user } = useAuth();
//   const [hasContent, setHasContent] = useState(() => {
//     return !!content && Object.keys(content).includes(contentKey) ? {...content[contentKey]} : {}
//   });
//   const [valid, setValid] = useState(() => {
//     return Object.keys(hasContent).length > 0;
//   })

//   useEffect(() => {
//     setHasContent(!!content && Object.keys(content) ? {...content} : {});
//   },[content])
//   useEffect(() => {
//     setValid(Object.keys(hasContent).length > 0);
//   },[content])
//  /*
//  team={Squad.CT}
//   label={'Pippo'}
//   type={Mode.Agent}
//   classes='add-button'
//   helperClasses={['skin']}
//   content={userContent[Squad.CT].agent}
//   handleClick={handleOpenDialog}
//   disable={!(user.isAdmin || user.isMod || user.tier == Tier.T3)}

//  */

//   /* <button
//               data-team={Squad.CT}
//               data-label={userContent[Squad.CT].agent ? null : "Agente"}
//               data-type={Mode.Agent}
//               className={`add-button ${userContent[Squad.CT].agent && 'skin'}`}
//               onClick={handleOpenDialog}
//               disabled={!(user.isAdmin || user.isMod || user.tier == Tier.T3)}
//             >
//               {}
//             </button> */
//   return (

//   )
// }

export default function Skins() {
  const { errorHandler } = useHttpError();
  const { user } = useAuth();
  // const [steamID] = useState(() => {
  //   return getSteamID();
  // })
  const [userContent, setUserContent] = useState({
    [Squad.CT]: {},
    [Squad.T]: {},
  });
  const { getContent, getAgents, getMusicKits, getKnives, getGloves, getWeaponCategories, getWeapons } = useSkins();

  const [isOpen, setIsOpen] = useState(false);
  const [minimumFloat] = useState(() => {
    let minimum = 0.01;

    let curr = {
      isAdmin: false,
      isMod: false,
    };

    curr.tier = user.tier;

    Object.keys(user).includes('isAdmin') && (curr.isAdmin = user.isAdmin);
    Object.keys(user).includes('isMod') && (curr.isAdmin = user.isMod);

    if(curr.isAdmin || curr.isMod || curr.tier === Tier.T3){
      return minimum;
    }

    switch(curr.tier){
      case Tier.T2: {
        minimum = 0.08;
        break;
      }
      case Tier.T1: {
        minimum = 0.16;
        break;
      }
      case Tier.COMMON: {
        minimum = 0.45;
        break;
      }
    }

    return minimum;
  })

  const [search, setSearch] = useState('');

  const [currentMode, setCurrentMode] = useState(Mode.NONE);
  const [currentTeam, setCurrentTeam] = useState(Squad.NONE);
  const [rawSkinsContent, setRawSkinsContent] = useState([]);
  const [addSkinsContent, setAddSkinsContent] = useState([]);
  const [activeWeapon, setActiveWeapon] = useState('');
  const [weaponOfChoice, setWeaponOfChoice] = useState('');
  const [enableWeaponConfig, setEnableWeaponConfig] = useState(false);
  const [selectedWeapon, setSelectedWeapon] = useState({});

  const [showLateralPanel, setShowLateralPanel] = useState(false);
  const [latPanelConfig, setLatPanelContent] = useState({});

  const { register, handleSubmit, watch, formState: { errors }, setValue, getValues, reset } = useForm();

  const onSubmit = useCallback(async (data) => {
    try {
      const send = {
        type: currentMode,
        team: mappedSquad.get(currentTeam),
        id: parseInt(selectedWeapon.paint,10),
        index: parseInt(selectedWeapon.weapon_defindex, 10),
        wear: data.float,
        seed: data.pattern,
        ...data,
      }

      await endpoint.post(`/skins`, JSON.stringify(send));
      refreshConfigs();

      fetchData();
    } catch(e) {
      console.log(e);
      errorHandler(e);
    }
  }, [selectedWeapon, errorHandler])

  const floatValue = watch("float");

  const fetchData = useCallback(async () => {

    try {
      const response = await endpoint.get(`/skins`)
      setUserContent({
        [Squad.CT]: response.ctside,
        [Squad.T]: response.tside,
      })

    } catch (e) {
      console.log(e);
      errorHandler(e);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData])

  useEffect(() => {
    if (!search) {
      setAddSkinsContent(rawSkinsContent);
      return;
    }
    const lowered = search.toLowerCase();
    let key;
    switch (currentMode) {
      case Mode.Agent: {
        key = 'agent_name';
        break;
      }
      case Mode.Music: {
        key = 'name';
        break;
      }

      default: {
        key = 'paint_name';
        break;
      }
    }

    setAddSkinsContent(rawSkinsContent.filter(element => element[key].toLowerCase().includes(lowered)))
  }, [search, rawSkinsContent, currentMode])

  useEffect(() => {
    const value = watch('pattern');
    (value < 0) && (setValue('pattern', 0));
    (value > 999) && (setValue('pattern', 999));
  }, [watch('pattern')])

  // useEffect(() => {
  //   const currentValue = watch('float');
  //   if (currentValue < minimumFloat) {
  //     setValue('float', minimumFloat);  // Only update if it's below the threshold
  //   }
  //   console.log(getValues('float'))
  // }, [watch('float')])

  const refreshConfigs = useCallback(() => {
    setIsOpen(false);

    setCurrentMode(Mode.NONE);
    setCurrentTeam(Squad.NONE);
    setRawSkinsContent([]);
    setAddSkinsContent([]);
    setActiveWeapon('');
    setWeaponOfChoice('');
    setSearch('');
    setEnableWeaponConfig(false);
    setSelectedWeapon({});
    reset();
  }, []);

  const ensureMinimumFloat = useCallback((ev) => {
    const floatChanged = ev.target.value;
    if(floatChanged < minimumFloat){
      setValue('float', parseFloat(minimumFloat));
    } else {
      setValue('float', parseFloat(floatChanged));
    }

  }, [minimumFloat])

  const handleOpenDialog = useCallback((ev) => {
    const { team, type, weapon } = ev.currentTarget.dataset;
    setCurrentTeam(team);

    [Mode.Knife, Mode.Glove].includes(type) && (setShowLateralPanel(true));
    [Mode.Agent, Mode.Music, Mode.Skins].includes(type) && (setShowLateralPanel(false));

    let current = [];

    switch (type) {
      case Mode.Agent: {
        current = getAgents(team);
        break;
      }
      case Mode.Music: {
        current = getMusicKits();
        break;
      }
      case Mode.Knife:
      case Mode.Glove: {
        setLatPanelContent(getContent(type))
        break;
      }
      default: {
        const theWeapon = getWeapons(weapon);
        setWeaponOfChoice(theWeapon.name);
        setActiveWeapon(weapon);
        current = theWeapon.content;
        break;
      }
    }

    setCurrentMode(type);
    // setRawSkinsContent(current);

    [Mode.Music, Mode.Agent, Mode.Skins].includes(type) && (setAddSkinsContent(current));
    [Mode.Music, Mode.Agent, Mode.Skins].includes(type) && (setRawSkinsContent(current));

    setIsOpen(true);
  }, [])

  const handlePreOpen = useCallback((ev) => {
    console.log(ev.currentTarget.dataset)
    const { forbiddenfor } = ev.currentTarget.dataset;
    // console.log(Tier.COMMON, Tier.T1, Tier.T2)
    if(!user.isAdmin && !user.isMod && forbiddenfor.includes(user.tier)){
      alert('👑 Este recurso não está disponível no teu plano Patrono atual');
      return
    }

    handleOpenDialog(ev);
  }, [user.tier]);

  const getSkinsContent = useCallback((ev) => {

    const { which } = ev.currentTarget.dataset;
    setActiveWeapon(which);
    let current = [];
    switch (currentMode) {
      case Mode.Knife: {
        current = getKnives(which).content;
        break;
      }
      case Mode.Glove: {
        current = getGloves(which).content;
        break;
      }
    }
    setSearch('');
    setRawSkinsContent(current);
    setAddSkinsContent(current);
  }, [currentMode, getKnives]);


  const handleSendAgentMusic = useCallback(async (ev) => {
    const { which } = ev.currentTarget.dataset;

    // if (steamID) {
      try {
        const send = {
          team: mappedSquad.get(currentTeam)
        }

        currentMode === Mode.Agent && (send.model = which);
        currentMode === Mode.Music && (send.music_id = parseInt(which, 10));
        await endpoint.post(`/skins/${currentMode}`, JSON.stringify(send));
        refreshConfigs();

        fetchData();

      } catch (e) {
        errorHandler(e);
        console.log(e);

      }
    // }
  }, [currentMode, currentTeam, refreshConfigs, fetchData, errorHandler])

  const handleEditMode = useCallback((ev) => {
    // data-which={which} data-={defindex} data-weapon_name={weapon_name}
    const { which, defindex, weapon_name, mode } = ev.currentTarget.dataset;
    const method = mode === Mode.Knife ? getKnives : (mode === Mode.Glove ? getGloves : getWeapons);
    const current_weapon = method(weapon_name);
    // console.log(mode)
    const selected_paint = current_weapon.content.find((item) => item.paint === which)

    setSelectedWeapon(selected_paint);
    setEnableWeaponConfig(true);
  }, [])

  const goBackWeaponConfigClick = useCallback(() => {
    setSelectedWeapon({});
    setEnableWeaponConfig(false);
  }, []);

  return (
    <div className="relative h-full flex flex-col flex-1 overflow-hidden">
      <div className='flex flex-row w-full gap-x-14 p-10 h-full'>
        <div className='own-skins ct'>
          <div className="spec-container">
          {/* <button
      className={`${classes.join(' ')} ${valid ? helperClasses.join(' ') : ''}`}
      data-team={team}
      data-label={valid ? null : label}
      data-type={type}
      onClick={handleClick}
      disabled={disable}
    >
      {disable ? ('👑'): userContent[Squad.CT].agent ? (
                <>
                  <img src={userContent[team][key].image} />
                  <p>{userContent[Squad.CT][contentKey].agent_name}</p>
                </>
              ) : (
                <FaCirclePlus />
              )}
    </button> */}
            <button
              data-team={Squad.CT}
              data-label={userContent[Squad.CT].agent ? null : "Agente"}
              data-type={Mode.Agent}
              className={`add-button ${userContent[Squad.CT].agent && 'skin'}`}
              data-forbiddenfor={[Tier.COMMON, Tier.T1, Tier.T2]}
              onClick={handlePreOpen}
            >
              {userContent[Squad.CT].agent ? (
                <>
                  <img src={userContent[Squad.CT].agent.image} />
                  <p>{userContent[Squad.CT].agent.agent_name}</p>
                </>
              ) : (
                <FaCirclePlus />
              )}
            </button>
            <button
              data-team={Squad.CT}
              data-label={userContent[Squad.CT].skins && userContent[Squad.CT].skins.knife ? null : "Faca"}
              data-type={Mode.Knife}
              className={`add-button ${userContent[Squad.CT].skins && userContent[Squad.CT].skins.knife && 'skin'}`}
              onClick={handleOpenDialog}
            >
              {userContent[Squad.CT].skins && userContent[Squad.CT].skins.knife ? (
                <>
                  <img src={userContent[Squad.CT].skins && userContent[Squad.CT].skins.knife.image} />
                  <p>{userContent[Squad.CT].skins && userContent[Squad.CT].skins.knife.paint_name}</p>
                </>
              ) : (
                <FaCirclePlus />
              )}
            </button>
            <button
              data-team={Squad.CT}
              data-label={userContent[Squad.CT].skins && userContent[Squad.CT].skins.glove ? null : "Luva"}
              data-type={Mode.Glove}
              className={`add-button ${userContent[Squad.CT].skins && userContent[Squad.CT].skins.glove && 'skin'}`}
              onClick={handleOpenDialog}
            >
              {userContent[Squad.CT].skins && userContent[Squad.CT].skins.glove ? (
                <>
                  <img src={userContent[Squad.CT].skins && userContent[Squad.CT].skins.glove.image} />
                  <p>{userContent[Squad.CT].skins && userContent[Squad.CT].skins.glove.paint_name}</p>
                </>
              ) : (
                <FaCirclePlus />
              )}
            </button>
            <button
              data-team={Squad.CT}
              data-label={userContent[Squad.CT].music_kit ? null : "Music Kit"}
              data-type={Mode.Music}
              className={`add-button ${userContent[Squad.CT].music_kit && 'skin'}`}
              data-forbiddenfor={[Tier.COMMON, Tier.T1]}
              onClick={handlePreOpen}
            >
              {userContent[Squad.CT].music_kit ? (
                <>
                  <img src={userContent[Squad.CT].music_kit.image} />
                  <p>{userContent[Squad.CT].music_kit.name}</p>
                </>
              ) : (
                <FaCirclePlus />
              )}
            </button>
          </div>

          <div className='flex flex-col'>
            <div className="overflow-y-auto">
              {getWeaponCategories().map((item) => (
                <SkinsByCategory category={item} team={Squad.CT} key={item} doSomething={handleOpenDialog} contentobject={userContent[Squad.CT].skins}/>
              ))}
            </div>
            {/* SKINS CONTAINER */}
          </div>
        </div>
        <div className='own-skins t'>
          <div className="spec-container">
            <button
              data-team={Squad.T}
              data-label={userContent[Squad.T].agent ? null : "Agente"}
              data-type={Mode.Agent}
              className={`add-button ${userContent[Squad.T].agent && 'skin'}`}
              data-forbiddenfor={[Tier.COMMON, Tier.T1, Tier.T2]}
              onClick={handlePreOpen}
            >
              {userContent[Squad.T].agent ? (
                <>
                  <img src={userContent[Squad.T].agent.image} />
                  <p>{userContent[Squad.T].agent.agent_name}</p>
                </>
              ) : (
                <FaCirclePlus />
              )}
            </button>
            <button
              data-team={Squad.T}
              data-label={userContent[Squad.T].skins && userContent[Squad.T].skins.knife ? null : "Faca"}
              data-type={Mode.Knife}
              className={`add-button ${userContent[Squad.T].skins && userContent[Squad.T].skins.knife && 'skin'}`}
              onClick={handleOpenDialog}
            >
              {userContent[Squad.T].skins && userContent[Squad.T].skins.knife ? (
                <>
                  <img src={userContent[Squad.T].skins && userContent[Squad.T].skins.knife.image} />
                  <p>{userContent[Squad.T].skins && userContent[Squad.T].skins.knife.paint_name}</p>
                </>
              ) : (
                <FaCirclePlus />
              )}
            </button>
            <button
              data-team={Squad.T}
              data-label={userContent[Squad.T].skins && userContent[Squad.T].skins.glove ? null : "Luva"}
              data-type={Mode.Glove}
              className={`add-button ${userContent[Squad.T].skins && userContent[Squad.T].skins.glove && 'skin'}`}
              onClick={handleOpenDialog}
            >
              {userContent[Squad.T].skins && userContent[Squad.T].skins.glove ? (
                <>
                  <img src={userContent[Squad.T].skins && userContent[Squad.T].skins.glove.image} />
                  <p>{userContent[Squad.T].skins && userContent[Squad.T].skins.glove.paint_name}</p>
                </>
              ) : (
                <FaCirclePlus />
              )}
            </button>
            <button
              data-team={Squad.T}
              data-label={userContent[Squad.T].music_kit ? null : "Music Kit"}
              data-type={Mode.Music}
              className={`add-button ${userContent[Squad.T].music_kit && 'skin'}`}
              data-forbiddenfor={[Tier.COMMON, Tier.T1]}
              onClick={handlePreOpen}
            >
              {userContent[Squad.T].music_kit ? (
                <>
                  <img src={userContent[Squad.T].music_kit.image} />
                  <p>{userContent[Squad.T].music_kit.name}</p>
                </>
              ) : (
                <FaCirclePlus />
              )}
            </button>
          </div>
          {/* SKINS CONTAINER */}
          <div className='flex flex-col flex-1 h-full overflow-y-auto '>
            {getWeaponCategories().map((item) => (
              <SkinsByCategory category={item} team={Squad.T} key={item} doSomething={handleOpenDialog} contentobject={userContent[Squad.T].skins}/>
            ))}
          </div>
        </div>
      </div>

      <Dialog open={isOpen} onClose={refreshConfigs} className="relative z-50">
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30">
          <DialogPanel className="container d-panel h-5/6">
            <div className="panel-title">
              <p>{mappedMode.get(currentMode)}{!!weaponOfChoice && ` | ${weaponOfChoice}`}</p>
              <button onClick={refreshConfigs}><FaTimes /></button>
            </div>
            <div className='flex h-full flex-row flex-1 overflow-hidden'>
              {!enableWeaponConfig && showLateralPanel && (
                <aside className="w-60 h-full p-4 overflow-y-auto" style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }}>
                  <nav className='px-2 gap-y-2 flex flex-col'>
                    {latPanelConfig.content.map((item) => (
                      <button
                        className={`lateral-panel-item ${item.value === activeWeapon && 'active'}`}
                        key={item.value}
                        data-which={item.value}
                        onClick={getSkinsContent}>{item.name}</button>
                    ))}
                  </nav>
                </aside>
              )}
              <div className='overflow-y-auto overflow-x-hidden relative w-full'>
                {rawSkinsContent.length > 0 && (
                  <>

                    {(!enableWeaponConfig && (activeWeapon || [Mode.Music, Mode.Agent, Mode.Skins].includes(currentMode))) && (
                      <div className='filter-container'>
                        <input onChange={(ev) => setSearch(ev.currentTarget.value)} value={search}></input>
                        {search.length > 0 && (
                          <button type="button" onClick={() => setSearch('')}><FaTimes /></button>
                        )}
                      </div>
                    )}

                    {!enableWeaponConfig && (
                      <div className='grid-container'>
                        {addSkinsContent.map((item) => {
                          const currentSetup = {
                            key: currentMode === Mode.Music ? 'id' : currentMode === Mode.Agent ? 'model' : 'paint',
                            which: currentMode === Mode.Music ? 'id' : currentMode === Mode.Agent ? 'model' : 'paint',
                            imgsrc: 'image',
                            label: currentMode === Mode.Music ? 'name' : currentMode === Mode.Agent ? 'agent_name' : 'paint_name',
                            defindex: ![Mode.Music, Mode.Agent].includes(currentMode) ? 'weapon_defindex' : null,
                            weapon_name: [Mode.Skins, Mode.Knife].includes(currentMode) ? 'weapon_name' : currentMode === Mode.Glove ? 'key' : null,
                            clickEvent: [Mode.Music, Mode.Agent].includes(currentMode) ? handleSendAgentMusic : handleEditMode,
                          }


                          return (
                            <SkinButton
                              mode={currentMode}
                              key={item[currentSetup.key]}
                              which={item[currentSetup.which]}
                              imgsrc={item[currentSetup.imgsrc]}
                              label={item[currentSetup.label]}
                              full={JSON.stringify(item)}
                              defindex={item[currentSetup.defindex]}
                              weapon_name={item[currentSetup.weapon_name]}
                              clickEvent={currentSetup.clickEvent}
                            />
                          )
                        })}
                      </div>
                    )}



                    {enableWeaponConfig && Object.keys(selectedWeapon).length > 0 && (
                      <div>
                        <GoBackButton goBackClick={goBackWeaponConfigClick} />
                        <div className="flex flex-col items-center justify-center my-3">
                          <span className='flex items-center justify-center bg-stone-300/15 rounded-full border-2 border-stone-300 px-2 w-40 h-40'>
                            <img src={selectedWeapon.image} className='h-auto w-full' />
                          </span>
                          <p className='text-stone-400'>{selectedWeapon.paint_name}</p>
                        </div>

                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="max-w-96 mx-auto p-6 rounded-lg shadow-lg bg-stone-900 border-stone-700 space-y-6 text-white"
                        >
                          <div className="space-y-2">
                            <label className="block text-sm font-medium text-stone-200">
                              Pattern:
                            </label>
                            <input
                              type="number"
                              min="1"
                              max="999"
                              step="1"
                              maxLength={3}
                              defaultValue="1"
                              {...register("pattern", {required: true, max: 999, min: 1})}
                              className="w-full px-4 py-2 text-stone-900 rounded-md border border-stone-600 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:border-stone-500 transition"
                            />
                          </div>

                          <div className="space-y-4 wear slider-container">
                            <label className="block text-sm font-medium text-stone-200">
                              Float:
                              <span className="font-semibold ml-1">{isNaN(floatValue) ? '-':parseFloat(floatValue).toFixed(2)}</span>
                            </label>
                            <input
                              type="range"
                              min="0.01"
                              max="1"
                              step="0.01"
                              value={floatValue || minimumFloat}
                              {...register("float", {required: true, max: 1, min: minimumFloat})}
                              onChange={ensureMinimumFloat}
                            />
                            <PremierResource>
                                {!user.isPremium ? (
                                  <p>👑 Para obter melhores floats, considere tornar-se um patrono!</p>
                                ) : user.isPremium && [Tier.T1, Tier.T2].includes(user.tier) && (
                                  <p>👑 Para obter melhores floats, considere fazer um upgrade do seu patrono!</p>
                                ) }
                              </PremierResource>
                          </div>
                          {currentMode !== Mode.Glove && (
                            <>
                              <div className="flex flex-col">
                                <div className="flex items-center space-x-4">
                                  <span className="text-sm font-medium text-stone-200">Stattrack</span>
                                  <label className="relative inline-block w-14 h-4">
                                    <input
                                      type="checkbox"
                                      defaultChecked={false}
                                      {...register("stattrak")}
                                      className="sr-only peer"
                                      disabled={[Tier.COMMON, Tier.T1].includes(user.tier)}
                                    />

                                    <div className="absolute inset-0 bg-stone-700 rounded-full peer-checked:bg-stone-600 transition-colors"></div>
                                    <FaCircleMinus className='knob minus'/>
                                    <FaCheckCircle className='knob checked' />
                                  </label>
                                </div>
                                <PremierResource>
                                    {!user.isPremium ? (
                                      <p>👑 Este é um recurso premium!</p>
                                    ) : user.isPremium && [Tier.T1].includes(user.tier) && (
                                      <p>👑 Para utilizar este recurso considere fazer um upgrade do seu patrono!</p>
                                    ) }
                                  </PremierResource>
                              </div>
                              <div className="space-y-2">
                                <label className="block text-sm font-medium text-stone-200">
                                  Nametag:
                                </label>
                                <input
                                  type="text"
                                  {...register("nametag")}
                                  className="w-full px-4 py-2 text-stone-900 rounded-md border border-stone-600 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:border-stone-500 transition"
                                />
                              </div>
                            </>
                          )}

                          <button
                            type="submit"
                            className="w-full py-2 px-4 bg-emerald-600 hover:bg-emerald-500 text-white font-semibold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-stone-400 focus:ring-offset-2 transition"
                          >
                            Submit
                          </button>
                        </form>

                          {/*
                          "seed": 0,
                          "wear": 0,
                          "nametag": "",
                          "stattrak": 1,
                          "stattrak_count": 39,
                          "sticker_0": null,
                          "sticker_1": null,
                          "sticker_2": null,
                          "sticker_3": null,
                          "sticker_4": null,
                          "keychain": null

                          */}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>

  )
}


function SkinCategoryButton({item, team, doSomething, contentobject}){
  const [existent, setExistent] = useState(() => {
    return contentobject || {}
  })

  useEffect(() => {
    setExistent(contentobject || {})
  }, [contentobject])
  return (
    <div className='flex flex-col items-center'>
    <button className="add-button-small" onClick={doSomething} data-team={team} data-type={Mode.Skins} data-weapon={item.value}>
      {Object.keys(existent).length > 0
        ? <img src={existent.image} title={existent.paint_name}/>
        : <FaCirclePlus />
      }
    </button>
    <p className="text-xs line-clamp-1">{item.name}</p>
    {/* {Object.keys(existent).length > 0 ? (<p className="text-xs line-clamp-1">{existent.paint_name}</p>) : ((<p className="text-xs line-clamp-1">&nbsp;</p>))} */}
    </div>
  )
}

function SkinsByCategory({ category, team, doSomething, contentobject }) {
  const { getContent } = useSkins();
  const [current, setCurrent] = useState({});


  useEffect(() => {
    if (category) {
      let current = getContent(category);
      current.content = current.content.filter((weapon) => [team, Squad.NONE].includes(weapon.team))
      setCurrent(current);
    }
  }, [category, team])

  return !!current.content && (
    <div className='flex flex-col justify-center mb-1 last:mb-0'>
      <p className='font-bold'>{current.label}</p>
      <div className="flex flex-row gap-x-3 items-center">
        {current.content.map((item) => (
          <SkinCategoryButton
            key={item.value}
            team={team}
            doSomething={doSomething}
            contentobject={(!!contentobject && Object.keys(contentobject).includes(item.value)) ? contentobject[item.value] : null }
            item={item}
          />
        ))}
      </div>
    </div>
  )
}

function SkinButton({ mode, which, imgsrc, label, clickEvent, defindex, weapon_name }) {

  return (
    <button className={`content-item ${![Mode.Agent, Mode.Music].includes(mode) && 'skin'}`} data-mode={mode} data-which={which} data-defindex={defindex} data-weapon_name={weapon_name} onClick={clickEvent}>
      <img src={imgsrc} />
      <p>{label}</p>
    </button>
  )
}

function GoBackButton({ goBackClick }) {
  return (
    <div className='goback-container'>
      <button onClick={goBackClick}>
        <FaArrowCircleLeft />
        <p>Seleção skin</p>
      </button>
    </div>
  )
}


